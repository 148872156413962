/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'person': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.511 5.624c0 1.8-1.542 3.313-3.511 3.313S5.49 7.424 5.49 5.624c0-1.8 1.541-3.313 3.51-3.313 1.97 0 3.511 1.512 3.511 3.313zm-1.239 4.087c-.15.076-.305.144-.464.205.103.02.206.042.308.065l-.026-.005a8.703 8.703 0 00-.282-.06 4.872 4.872 0 00.464-.205zm2.882-4.087c0 1.781-.989 3.335-2.462 4.198 3.201.935 5.608 3.503 5.608 6.673a.813.813 0 01-.822.805.813.813 0 01-.821-.805c0-2.892-2.854-5.442-6.657-5.442s-6.656 2.55-6.656 5.442a.813.813 0 01-.822.805.835.835 0 01-.42-.114.797.797 0 01-.402-.69c0-3.17 2.407-5.74 5.608-6.674-1.473-.863-2.462-2.417-2.462-4.198C3.846 2.891 6.167.7 9 .7c2.833 0 5.154 2.19 5.154 4.924z" _fill="#898B9B"/>'
  }
})
